<template>
    <v-container fluid style="padding-bottom: 50px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text class="p-2">
                        <v-container fluid>
                            <v-row>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal"
                                        v-model="modal"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            outlined
                                            dense
                                            v-model="date_from"
                                            label="Last Period"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            type="month"
                                            v-model="date_from"
                                            no-title
                                            @input="modal = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" lg="1" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-account-hard-hat"
                                        clearable
                                        dense
                                        solo
                                        v-model="division"
                                        :items="divisions"
                                        item-value="value"
                                        :item-text="item => item.text"
                                        label="Division"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :disabled="division_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building"
                                        clearable
                                        dense
                                        solo
                                        v-model="region_proyek"
                                        :items="region_proyeks"
                                        item-value="region_proyek"
                                        :item-text="item => item.region_proyek"
                                        label="Region Proyek"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        @change="(event) => getRegion(event)"
                                        :disabled="region_proyek_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building"
                                        clearable
                                        dense
                                        solo
                                        v-model="region"
                                        :items="regions"
                                        item-value="region"
                                        :item-text="item => item.region"
                                        label="Region"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        @change="(event) => getOffice(event)"
                                        :disabled="region_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building-outline"
                                        clearable
                                        dense
                                        solo
                                        v-model="office"
                                        :items="offices"
                                        item-value="office_id"
                                        :item-text="item => item.office.trim()"
                                        label="Office"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_office"
                                        @change="(event) => getSales(event)"
                                        :disabled="office_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="3" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-clipboard-account"
                                        clearable
                                        dense
                                        solo
                                        v-model="sales"
                                        :items="saless"
                                        item-value="SalesId"
                                        :item-text="item => item.NamaSales"
                                        label="Sales"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        :loading="loading_sales"
                                        :disabled="sales_disabled"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="3" class="col-12">
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="customer"
                                        :items="customers"
                                        :search-input.sync="searchCustomer"
                                        cache-items
                                        hide-no-data
                                        hide-details
                                        allow-overflow
                                        label="Customer"
                                        item-value="CustomerId"
                                        item-text="NamaCustomer"
                                        class="ma-0 pa-0 border-12"
                                        :loading="loading_customer"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="6">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-12">
            <v-col class="col-12" sm="12" md="12">
                <v-card outlined class="p-0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    :items="histories"
                                    class="elevation-1"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="425"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchItemTrans" 
                                    :item-class="tr_datatable"
                                >   
                                <!-- group-by="NamaCustomer"  -->
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List History </h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <v-btn
                                                small
                                                color="#005c37"
                                                class="py-5 mr-3 text-white border-12"
                                                >
                                                    <v-icon>mdi-file-excel</v-icon>
                                                    <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :data="histori_exports"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        Export Excel
                                                    </download-excel>
                                                </v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.alamat`]="{ item }">
                                        {{ item.Kota }} - {{ item.Alamat }}
                                    </template>

                                    <template v-slot:[`item.${headers[2].value}`]="{ item }">
                                        <v-btn 
                                            v-model="selectedRows"
                                            :value="item"
                                            block
                                            elevation="0"
                                            @click="openDialog(item, headers[2].value)"
                                        >
                                        {{item[headers[2].value]}}
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.${headers[3].value}`]="{ item }">
                                        <v-btn 
                                            v-model="selectedRows"
                                            :value="item"
                                            block
                                            elevation="0"
                                            @click="openDialog(item, headers[3].value)"
                                        >
                                        {{item[headers[3].value]}}
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.${headers[4].value}`]="{ item }">
                                        <v-btn 
                                            v-model="selectedRows"
                                            :value="item"
                                            block
                                            elevation="0"
                                            @click="openDialog(item, headers[4].value)"
                                        >
                                        {{item[headers[4].value]}}
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.${headers[5].value}`]="{ item }">
                                        <v-btn 
                                            v-model="selectedRows"
                                            :value="item"
                                            block
                                            elevation="0"
                                            @click="openDialog(item, headers[5].value)"
                                        >
                                        {{item[headers[5].value]}}
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.${headers[6].value}`]="{ item }">
                                        <v-btn 
                                            v-model="selectedRows"
                                            :value="item"
                                            block
                                            elevation="0"
                                            @click="openDialog(item, headers[6].value)"
                                        >
                                        {{item[headers[6].value]}}
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.${headers[7].value}`]="{ item }">
                                        <v-btn 
                                            v-model="selectedRows"
                                            :value="item"
                                            block
                                            elevation="0"
                                            @click="openDialog(item, headers[7].value)"
                                        >
                                        {{item[headers[7].value]}}
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.${headers[8].value}`]="{ item }">
                                        <v-btn 
                                            v-model="selectedRows"
                                            :value="item"
                                            block
                                            elevation="0"
                                            @click="openDialog(item, headers[8].value)"
                                        >
                                        {{item[headers[8].value]}}
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.${headers[9].value}`]="{ item }">
                                        <v-btn 
                                            v-model="selectedRows"
                                            :value="item"
                                            block
                                            elevation="0"
                                            @click="openDialog(item, headers[9].value)"
                                        >
                                        {{item[headers[9].value]}}
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.${headers[10].value}`]="{ item }">
                                        <v-btn 
                                            v-model="selectedRows"
                                            :value="item"
                                            block
                                            elevation="0"
                                            @click="openDialog(item, headers[10].value)"
                                        >
                                        {{item[headers[10].value]}}
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.${headers[11].value}`]="{ item }">
                                        <v-btn 
                                            v-model="selectedRows"
                                            :value="item"
                                            block
                                            elevation="0"
                                            @click="openDialog(item, headers[11].value)"
                                        >
                                        {{item[headers[11].value]}}
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.${headers[12].value}`]="{ item }">
                                        <v-btn 
                                            v-model="selectedRows"
                                            :value="item"
                                            block
                                            elevation="0"
                                            @click="openDialog(item, headers[12].value)"
                                        >
                                        {{item[headers[12].value]}}
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.${headers[13].value}`]="{ item }">
                                        <v-btn 
                                            v-model="selectedRows"
                                            :value="item"
                                            block
                                            elevation="0"
                                            class="py-5 mr-3 border-12"
                                            @click="openDialog(item, headers[13].value)"
                                        >
                                        {{item[headers[13].value]}}
                                        </v-btn>
                                    </template>

                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
        v-model="dialogDetailItem"
        persistent
        min-width="650"
        max-width="650"
        >
            <v-card height="600" style="overflow: auto;">
                <v-card-title class="text-center mb-0 pb-0">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <div class="ml-0">
                                Detil Customer
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                class="mx-2"
                                icon
                                x-small
                                color="#e83e8c;"
                                @click="[dialogDetailItem = false]"
                                >
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text class="">
                    <v-card flat>
                        <v-container fluid>
                            <v-row>
                                <v-col class="col-12 py-0" md="6">
                                    <v-container fluid>
                                        <v-row> 
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-10">Credit Limit</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-12 font-weight-bold">Rp. {{ DetailCreditLimitModel ? DetailCreditLimitModel : 0 }}</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-10">Piutang</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-12 font-weight-bold">Rp. {{ DetailPiutangModel ? DetailPiutangModel : 0 }}</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-10">Credit Limit Remaining</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-12 font-weight-bold">Rp. {{ DetailSisaCreditLimitodel ? DetailSisaCreditLimitodel : 0 }}</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-10">Outstanding Order</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-12 font-weight-bold">Rp. {{ DetailOutsOrderModel ? DetailOutsOrderModel : 0 }}</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-10">Last Customer Visit</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-12 font-weight-bold">{{ DetailLastVisitModel ? DetailLastVisitModel : 0 }}</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-10">Total Weight(Ton)</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="6">
                                                <span class="mb-0 font-12 font-weight-bold">{{ DetailTotalWgtModel ? DetailTotalWgtModel : 0 }}</span>
                                            </v-col>
                                            
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col class="col-12 py-0" md="6">
                                    <v-container fluid>
                                        <v-row> 
                                            <v-col class="v-col-6 py-0" md="7">
                                                <span class="mb-0 font-10">Belum Jatuh Tempo</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="5">
                                                <span class="mb-0 font-12 font-weight-bold">Rp. {{$store.getters.convertToCurrencyNoFrac(summaryInv ? summaryInv.belum_jatuh_tempo : 0)}}</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="7">
                                                <span class="mb-0 font-10">Jatuh Tempo 1- 30 Hari</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="5">
                                                <span class="mb-0 font-12 font-weight-bold">Rp. {{$store.getters.convertToCurrencyNoFrac(summaryInv ? summaryInv.hari1to30 : 0)}}</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="7">
                                                <span class="mb-0 font-10">Jatuh Tempo 31-60 Hari</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="5">
                                                <span class="mb-0 font-12 font-weight-bold">Rp. {{$store.getters.convertToCurrencyNoFrac(summaryInv ? summaryInv.hari31to60 : 0)}}</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="7">
                                                <span class="mb-0 font-10">Jatuh Tempo 61-90 Hari</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="5">
                                                <span class="mb-0 font-12 font-weight-bold">Rp. {{$store.getters.convertToCurrencyNoFrac(summaryInv ? summaryInv.hari61to90 : 0)}}</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="7">
                                                <span class="mb-0 font-10">Jatuh Tempo 91-365 Hari</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="5">
                                                <span class="mb-0 font-12 font-weight-bold">Rp. {{$store.getters.convertToCurrencyNoFrac(summaryInv ? summaryInv.hari91to365 : 0)}}</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="7">
                                                <span class="mb-0 font-10">Jatuh Tempo 365 Hari Keatas</span>
                                            </v-col>
                                            <v-col class="v-col-6 py-0" md="5">
                                                <span class="mb-0 font-12 font-weight-bold">Rp. {{$store.getters.convertToCurrencyNoFrac(summaryInv ? summaryInv.hari366plus : 0)}}</span>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>                                
                            </v-row>
                            <v-row class="mt-5">
                                <v-col cols="12">
                                    <v-data-table
                                        dense
                                        :headers="headersDetails"
                                        :items="itemDetailLists"
                                        class="elevation-1 font-12"
                                        :items-per-page="30"
                                        :footer-props="{
                                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                        }"
                                        :loading="loadingDatatableDetail"
                                        height="250"
                                        fixed-header
                                        :divider="true"
                                        :light="true"
                                    >  
                                        <template v-slot:[`item.index`]="{ index }">
                                            {{ index + 1 }}
                                        </template>
                                    </v-data-table>  
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        plain
                        @click="dialogDetailItem = false"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            search:'',
            breadcumbs: [
                {
                    text: 'KMB',
                    disabled: false,
                    href: '/admin/kmb',
                },
                {
                    text: 'E-Reports',
                    disabled: true,
                },
                {
                    text: 'Customer History',
                    disabled: true,
                }
            ],
            regions: [],
            region: '',
            offices: [],
            office: '',
            saless: [],
            sales: '',
            customers: [],
            customer: '',
            consignes: [],
            consigne: '',
            divisions: [
                {
                    value: 'all',
                    text: 'All'
                },
                {
                    value: 'RETAIL',
                    text: 'RETAIL'
                },
                {
                    value: 'PROYEK',
                    text: 'PROYEK'
                }
            ],
            division: 'all',
            modal: false,
            date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            searchItemTrans: '',
            histories: [],
            headers: [],
            headersColumn: {},
            months: [],
            loading_office: false,
            loading_sales: false,
            loading_customer: false,
            loading_consigne: false,
            region_disabled: false,
            office_disabled: false,
            sales_disabled: false,
            division_disabled: false,
            searchCustomer: null,
            histori_exports: [],
            region_proyek: '',
            region_proyeks:[],
            region_proyek_disabled: false,
            selectedRows:[],
            dialogDetailItem: false,
            DetailCreditLimitModel: '',
            DetailPiutangModel: '',
            DetailSisaCreditLimitodel: '',
            DetailOutsOrderModel: '',
            DetailLastVisitModel: '',
            DetailTotalWgtModel: '',
            itemDetailLists: [],
            headersDetails: [
                { text: 'NO.', value: 'index', align: 'left', class: 'primary--text blue lighten-5', width: '5%', sortable: false },
                { text: 'PROD. CODE', value: 'BarangJadiId', align: 'left', class: 'primary--text blue lighten-5', width: '10%', sortable: false },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5', width: '70%', sortable: false },
                { text: 'WEIGHT(KG)', value: 'berat', align: 'right', class: 'primary--text blue lighten-5', width: '5%', sortable: false },
            ],
            loadingDatatableDetail: false,
            tab: 'tab-1',
            summaryInv: null,
        }
    },
    computed:{
    },
    watch: {
        searchCustomer (val) {
            val && val !== this.customer && this.getCustomer(val)
        },
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)  
        this.getMonth(this.date_from, 12)
        await this.getEnvConf()
        await this.getRegionProyek()
        // await this.getOffice('KMB')
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable(item) {
            var rowClass = 'tr_datatable_history'
            return rowClass;
        },
        clear(){
            if (this.region_disabled == false) {
                this.region = ''
            }
            if (this.office_disabled == false) {
                this.office = ''
            }
            if (this.sales_disabled == false) {
                this.sales = ''
            }
            if (this.division_disabled == false) {
                this.division = ''
            }
            this.customer = ''
            this.consigne = ''
        },
        async getEnvConf(){
            const respDataRegionProyek = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=REGIONPROYEK&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)  
            
            if (respDataRegionProyek.status == 200) {
                this.region_proyek = respDataRegionProyek.data.data ? respDataRegionProyek.data.data : ''
                this.region_proyek_disabled = respDataRegionProyek.data.data ? true : false
                if (this.region_proyek_disabled) {
                    await this.getRegionProyek()
                    await this.getRegion(this.region_proyek)
                //     await this.getOffice(this.region_proyek)
                } else {
                    await this.getRegion('KMB')
                }
            }

            if (this.$store.state.kmb.group_id != 'RM PROJECT') {
                const respDataRegion = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=REGION&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)  
                
                if (respDataRegion.status == 200) {
                    this.region = respDataRegion.data.data ? respDataRegion.data.data : ''
                    this.region_disabled = respDataRegion.data.data ? true : false
                    if (this.region_disabled) {
                        this.region_proyek_disabled = true
                        await this.getRegionProyek()
                        await this.getRegion(this.region)
                        await this.getOffice(this.region)
                    }
                }
            }

            const respDataOffice = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=BRANCH&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)  
            
            if (respDataOffice.status == 200) {
                this.office = respDataOffice.data.data ? respDataOffice.data.data : ''
                this.office_disabled = respDataOffice.data.data ? true : false
                if (this.office_disabled) {
                    this.region_proyek_disabled = true
                    this.region_disabled = true
                    await this.getRegionProyek()
                    await this.getRegion(this.office)
                    await this.getOffice(this.office)
                    await this.getSales(this.office)
                } else {
                    await this.getOffice('KMB')
                }
            }

            const respDataSales = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=SALESID&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)  
            
            if (respDataSales.status == 200) {               
                if (respDataSales.data.data) {
                    if (this.$store.state.kmb.group_id == 'RM KMB' || this.$store.state.kmb.group_id == 'RM PROJECT' || this.$store.state.kmb.group_id == 'KACAB KMB') {
                        this.sales_disabled = false
                    } else {
                        this.sales = respDataSales.data.data ? respDataSales.data.data.toUpperCase() : ''
                        this.sales_disabled = respDataSales.data.data ? true : false

                        if (this.sales_disabled) {
                            this.region_proyek_disabled = true
                            this.region_disabled = true
                            this.office_disabled = true
                            await this.getSales(this.sales)
                            await this.getCustomer(this.sales)
                        }
                    }
                    
                }
            }
        },
        async getRegionProyek(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kmb/branch_office/region_proyek?region_proyek=${this.region_proyek ? this.region_proyek : ''}&region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.region_proyeks = res.data.data
                if (this.region_proyek_disabled && res.data.data.length > 0) {
                    this.region_proyek = res.data.data[0].region_proyek
                    this.region_proyek_disabled = true
                } else {
                    this.region_proyek = ''
                    this.region_proyek_disabled = false
                }
            });
        },
        async getRegion(event){
            if (event == '' || event == null) {
                this.region_proyek = ''
                this.region = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kmb/branch_office/region?region_proyek=${this.region_proyek ? this.region_proyek : ''}&region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.regions = res.data.data
                    if (this.region_disabled && res.data.data.length > 0) {
                        this.region = res.data.data[0].region
                        this.region_disabled = true
                    } else {
                        this.region = ''
                        this.region_disabled = false
                    }
                    
                });
            }
        },
        async getOffice(event){
            if (event == '' || event == null) {
                this.office = ''
                this.region = ''
            } else {
                this.loading_office = true
                this.offices = []
                await axios.get(`${process.env.VUE_APP_URL}/api/master/kmb/branch_office/office?region_proyek=${this.region_proyek ? this.region_proyek : ''}&region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_office = false
                    this.offices = res.data.data
                });
            }
        },
        async getSales(event){
            if (event == '' || event == null) {
                this.office = ''
                this.sales = ''
            } else {
                this.loading_sales = true

                if (this.$store.state.kmb.group_id == 'RM KMB' || this.$store.state.kmb.group_id == 'RM PROJECT' || this.$store.state.kmb.group_id == 'KACAB KMB') {
                    var salesId = ''
                } else {
                    var salesId = this.sales ? this.sales : ''
                }

                await axios.get(`${process.env.VUE_APP_URL}/api/master/kmb/sales?office_id=${this.office ? this.office : ''}&SalesId=${salesId}&active_flag=Y`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_sales = false
                    this.saless = res.data.data
                    if (this.sales_disabled && res.data.data.length > 0) {
                        this.office = res.data.data[0].office_id
                        this.office_disabled = true
                        this.getRegionProyek()
                        this.getRegion(this.office)
                        this.getOffice(this.office)
                    }
                });
                
            }
        },
        async getCustomer(event){
            if (event == '' || event == null) {
                this.sales = ''
                this.customer = ''
            } else {
                this.loading_customer = true
                await axios.get(`${process.env.VUE_APP_URL}/v2/master/kmb/customer?customer=${event}&sales=${this.sales ? this.sales : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_customer = false
                    this.customers = res.data
                });
            }
        },
        async getConsignee(event){
            if (event == '' || event == null) {
                this.customer = ''
                this.consigne = ''
            } else {
                this.loading_consigne = true
                await axios.get(`${process.env.VUE_APP_URL}/v2/master/kmb/consignee?customerid=${this.customer ? this.customer : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading_consigne = false
                    this.consignes = res.data
                });
            }
        },
        getMonth(start, value){
            this.months = []
            this.headers = []
            const monthString = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            for (let index = 0; index <= value; index++) {
                if (index == 0) {
                    var startDate = new Date( Date.parse(start) - (new Date()).getTimezoneOffset() * 60000)
                    let name = monthString[startDate.getMonth()];
                    this.months.push({
                        value: start.replace(/-/g,""),
                        text: start
                    })
                } else {
                    var startDate = new Date( Date.parse(start) - (new Date()).getTimezoneOffset() * 60000)
                    var startDayofMonth = new Date(startDate.getFullYear(), (startDate.getMonth() + 1) - index, 0);
                    // console.log(startDayofMonth);
                    var month = (new Date(startDayofMonth - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
                    let name = monthString[(new Date(startDayofMonth - (new Date()).getTimezoneOffset() * 60000)).getMonth()];

                    this.months.push(
                        {
                            value: month.replace(/-/g,""),
                            text: month
                        }    
                    )
                }                  

            }

            this.headers = [
                { text: 'Customer', value: 'NamaCustomer', width:300, align: 'left' },
                { text: 'Consignee', value: 'alamat', width:300, align: 'left', groupable:false, class: 'my-border' },
                { text: this.months[0].text, value: this.months[0].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[1].text, value: this.months[1].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[2].text, value: this.months[2].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[3].text, value: this.months[3].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[4].text, value: this.months[4].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[5].text, value: this.months[5].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[6].text, value: this.months[6].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[7].text, value: this.months[7].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[8].text, value: this.months[8].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[9].text, value: this.months[9].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[10].text, value: this.months[10].value, width:100, align: 'right', groupable:false, class: 'my-border' },
                { text: this.months[11].text, value: this.months[11].value, width:100, align: 'right', groupable:false, class: 'my-border' }
            ]
        },
        async searchFunction(){
            this.$store.dispatch('setOverlay', true)  

            if (this.region === '' || this.office === '' || this.sales === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Fill Region, Office, Sales',
                    visible: true
                };

                this.$store.dispatch('setOverlay', false)  
                return false
            }

            this.getMonth(this.date_from, 12)

            var reqBody = {
                'region_proyek': this.region_proyek ? this.region_proyek : '',
                'region': this.region ? this.region : '',
                'office_id': this.office ? this.office : '',
                'SalesId': this.sales ? this.sales : '',
                'CustomerId': this.customer ? this.customer : '',
                'startPeriod': this.months.length > 0 ? this.months[11].value : '',
                'endPeriod': this.months.length > 0 ? this.months[0].value : '',
                'months' : this.months,
                'divisi': this.division === 'all' ? '' : this.division,
            }

            const respData = await backendApi.fetchCore('/v2/kmb/order_consignee', reqBody, false, false, false)
            if (respData.status === 200) {
                this.histories = respData.data
                this.$store.dispatch('setOverlay', false)  
            }

        },
        async exportExcel(){
            if (this.region === '' || this.office === '' || this.sales === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please Fill Region, Office, Sales',
                    visible: true
                };

                this.$store.dispatch('setOverlay', false)  
                return false
            }
            
            this.getMonth(this.date_from, 12)

            var reqBody = {
                'region_proyek': this.region_proyek ? this.region_proyek : '',
                'region': this.region ? this.region : '',
                'office_id': this.office ? this.office : '',
                'SalesId': this.sales ? this.sales : '',
                'CustomerId': this.customer ? this.customer : '',
                'startPeriod': this.months.length > 0 ? this.months[11].value : '',
                'endPeriod': this.months.length > 0 ? this.months[0].value : '',
                'months' : this.months,
                'divisi': this.division === 'all' ? '' : this.division,
            }

            const respData = await backendApi.fetchCore('/v2/kmb/order_consignee', reqBody, false, false, false)
            if (respData.status === 200) {
                this.histori_exports = respData.data

                return this.histori_exports
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
        getRowData(item, index, event) {
            // console.log('Clicked row:', item);
            // console.log('index', index);
            // console.log('event', event);
            // // Get the column header of the clicked cell
            // const columnHeader = event.target.closest('th').textContent.trim();
            // // Get the value of the clicked cell
            // const cellValue = item[columnHeader.toLowerCase()];
            // console.log(`Selected value in ${columnHeader}: ${cellValue}`);
        },

        async openDialog(item, head){

            console.log("item :", item)
            console.log("head :", head)
            console.log("customerId :", item["CustomerId"])
            console.log("value :", item[head])

            this.itemDetailLists = []
            this.DetailCreditLimitModel = 0
            this.DetailPiutangModel = 0
            this.DetailSisaCreditLimitodel = 0
            this.DetailOutsOrderModel = 0
            this.DetailLastVisitModel = '-'
            this.DetailTotalWgtModel = 0
            this.dialogDetailItem = true
            this.loadingDatatableDetail = true
            await this.summary(item)
            await axios.post(`${process.env.VUE_APP_URL}/v2/kmb/order_consignee/getDetail`, { 
                cust_id: item["CustomerId"],
                period: head
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                
                this.loadingDatatableDetail = false
                this.itemDetailLists = res.data.resultItem
                this.DetailCreditLimitModel = this.formatNumber(res.data.resultHeader.cl_amt)
                this.DetailPiutangModel = this.formatNumber(res.data.resultHeader.piutang_amt)
                this.DetailSisaCreditLimitodel = this.formatNumber(res.data.resultHeader.cl_amt_sisa)
                this.DetailOutsOrderModel = this.formatNumber(res.data.resultHeader.out_amt)
                this.DetailLastVisitModel = res.data.resultHeader.last_visit
                this.DetailTotalWgtModel = item[head]

            })
            .catch(err => {

                console.log(err)

            })

            

        },

        async summary(item){

            var reqBody = {
                'cust_id': item["CustomerId"] ? item["CustomerId"] : '',
                'SalesId': this.sales ? this.sales : '',
                'byWhat': ''
            }

            const respData = await backendApi.fetchCore('/api/v3/kmb/finance/piutang/summary-total', reqBody, false, false, false)

            if(respData.status === 200){
                console.log(respData.data);
                this.summaryInv = respData.data.data.length > 0 ? respData.data.data[0] : NULL
            }
        },

        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    },    
}
</script>